export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en: {
      column_headers: {
        asset: {
          bank: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          creditCard: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          investment: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          loan: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          retirement: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          vehicle: {
            name: 'Account name',
            institution: 'Vehicle type',
            value: 'Current Value',
          },
          realEstate: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          business: {
            name: 'Account name',
            institution: 'Business type',
            value: 'Current Value',
          },
          intangible: {
            name: 'Asset name',
            institution: 'Asset type',
            value: 'Current Value',
          },
          socialMediaAccount: {
            name: 'Account name',
            institution: 'Social media platform',
            value: 'Current Value',
          },
          collectible: {
            name: 'Asset name',
            institution: '',
            value: 'Current Value',
          },
          jewelry: {
            name: 'Asset name',
            institution: '',
            value: 'Current Value',
          },
          trust: {
            name: 'Name of trust',
            institution: 'Type',
            value: 'Current Value',
          },
          cash: {
            name: 'Asset name',
            institution: '',
            value: 'Current Value',
          },
          crypto: {
            name: 'Asset name',
            institution: '',
            value: 'Current Value',
          },
          other: {
            name: 'Account name',
            institution: 'Asset type',
            value: 'Current Value',
          },
        },
      },
      marital_property: {
        earned_income: 'Earned Income',
        passive_income: 'Passive Income',
        investment_accounts: 'Investment Accounts',
        retirement_accounts: 'Retirement Accounts',
        vehicles: 'Vehicles',
        real_estate: 'Real Estate',
        business_interests: 'Business Interests',
        other_assets: 'Other Assets',
        other_loans_and_debt: 'Other Loans and Debt',
        student_loans: 'Student Loans',
      },
      section_status: {
        not_started: 'Not Started',
        next: 'Up next',
        active: 'Get started',
        started: 'Continue',
        completed: 'Completed',
        review: 'Review',
        update_review: 'Review',
        waiting: 'Waiting',
      },
      section_status_partner: {
        not_started: 'Not Started',
        next: 'Not Started',
        active: 'Not Started',
        started: 'In progress',
        completed: 'Completed',
        review: 'In progress',
        update_review: 'In progress',
        waiting: 'Waiting',
      },
      allocation: {
        true: {
          default: 'Keep separate',
          ny: 'Keep separate'
        },
        false: {
          default: 'Make community',
          ny: 'Make marital'
        }
      },
      allocation_button: {
        true: 'Keep separate property',
        false: {
          default: 'Make community property',
          ny: 'Make marital property',
        }
      },
      agree: {
        true: 'Agree',
        false: 'Disagree',
        null: '',
      },
      our_prenup_header: {
        drafting: {
          card: `Once your prenup is marked final by you and your partner, it will become available to sign in {daysLeft} days.`,
          header: 'California has a 7-day rule for prenups.',
          description:
            'This is a rule that ensures both partners have seven calendar days to contemplate the agreement. Calendar days include weekends and holidays. We encourage you to review all of your documents during this time period.',
          status: 'View',
        },
        signing: {
          card: ({ values }: { values: { partnerName: string; userSigned: boolean } }) => {
            return values.userSigned
              ? `Waiting for ${values.partnerName} to sign the prenup.`
              : 'Check your inbox for an email from Docusign to begin the notarization process.'
          },
          header: ({ values }: { values: { partnerName: string; userSigned: boolean } }) => {
            return values.userSigned
              ? `Once ${values.partnerName} signs, it will be official.`
              : 'Your prenup is ready!'
          },
          description: ({ values }: { values: { partnerName: string; userSigned: boolean } }) => {
            return values.userSigned ? '' : `It is time to sign your prenup.`
          },
          status: ({ values }: { values: { partnerName: string; userSigned: boolean } }) => {
            return 'Your Prenup'
            // We show 'Your Prenup' until Docusign releases a new notary API webhook notary-session-completed
            // return values.userSigned ? 'View' : 'Sign prenup'
          },
          statuss: 'Sign prenup',
        },
        finalized: {
          card: '',
          header: 'Signed. Sealed. Delivered. The prenup is yours.',
          description: 'You can come back here to view or download your documents at any time.',
          status: 'Download',
        },
      },
      partners_postmarital_property: {
        title: {
          all_community: {
            default: "{partnerName} has decided to treat all assets and debt acquired during your marriage as community property.",
            ny: "{partnerName} has decided to treat all assets and debt acquired during your marriage as marital property."
          },
          all_separate: "{partnerName} has decided to treat all assets and debt acquired during your marriage as separate property.",
          mixed: "{partnerName} would like some property acquired during marriage to stay separate.",
        },
        description: {
          all_community: {
            default: "This is the default California rule.",
            ny: ""
          },
          mixed: "{partnerName} will review any allocations you dispute."
        },
      },
      premarital_property: {
        title: "Review your premarital property.",
        description: {
          default: "Here's the full list of your premarital assets as well as an indication of which ones you want to keep separate or make community property after wedding day. On this page you can keep them as is or update ownership splits if you've changed your mind.",
          ny: "Here's the full list of your premarital assets as well as an indication of which ones you want to keep separate or make marital property after wedding day. On this page you can keep them as is or update ownership splits if you've changed your mind."
        },
        empty_title: "You have no premarital property.",
        empty_description: "Only assets or loans that are solely in your name and not shared will appear here."
      },
      postmarital_property: {
        title: {
          all_community: {
            default: "You have decided to treat all assets and debt acquired during your marriage as community property.",
            ny: "You have decided to treat all assets and debt acquired during your marriage as marital property."
          },
          all_separate:
            'You have decided to treat all assets and debt acquired during your marriage as separate property.',
          mixed: 'You decided some of your property acquired during marriage should stay separate.',
        },
      },
    },
  },
}))
